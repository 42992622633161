import axios from '@axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTemplates(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/template', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/template/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateTemplate(ctx, { templateData, id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/api/template/${id}`, templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteTemplate(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/template/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addTemplate(ctx, templateData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/template', templateData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchAllFeatures(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/property-type/all-features')
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

  },
};
